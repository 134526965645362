<template>
	<v-dialog v-model="dialog" persistent scrollable :max-width="$vuetify.breakpoint.mdAndUp ? '60vw' : '90vw'">
		<v-card>
			<v-card-title class="align-center secondary justify-space-between">
				<h1 v-t="'quickfolders.management'" class="headline white--text" />
				<v-btn class="white--text" icon @click="$emit('close')">
					<v-icon>close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-data-table class="elevation-1" :headers="headers" hide-actions :items="quickfolders">
					<template v-slot:items="{ item: quickfolder, item: { folder } }">
						<tr>
							<td v-text="folder.location + folder.name" />
							<td>
								<v-edit-dialog lazy @open="openEditDialog(quickfolder.name)" @save="updateQuickFolders(quickfolder)">
									{{ quickfolder.name }}
									<template v-slot:input>
										<w-text-input v-model="quickfolderName" :label="$t('actions.update')" maxlength="191" required single-line></w-text-input>
									</template>
								</v-edit-dialog>
							</td>
							<td class="text-xs-center">
								<w-btn-delete mini no-text @click="deleteQuickFolder(quickfolder.id)" />
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'QuickFoldersSettings',
	mixins: [QuickFoldersModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		},
		quickfolders: {
			required: true,
			type: Array
		}
	},
	data: () => ({
		dialog: true,
		quickfolderName: ''
	}),
	computed: {
		headers: function () {
			return [
				{
					align: 'center',
					text: this.$t('quickfolders.folder_name'),
					value: 'folder_name'
				},
				{
					align: 'center',
					text: this.$t('quickfolders.quickfolder_name'),
					value: 'name'
				},
				{ align: 'center', text: this.$t('actions.delete'), sortable: false }
			]
		}
	},
	methods: {
		deleteQuickFolder: function (quickfolderId) {
			this.service.destroy(this.company.id, quickfolderId).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('quickfolders.actions.delete'))
				this.$emit('remove', quickfolderId)
				this.$emit('close')
			})
		},
		openEditDialog: function (name) {
			this.quickfolderName = name
		},
		updateQuickFolders: function (quickfolder) {
			if (!this.quickfolderName) {
				return
			}

			this.service
				.update(this.company.id, quickfolder.id, {
					name: this.quickfolderName
				})
				.then(res => {
					this.$emit('replace', res)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('quickfolders.actions.update'))
					this.$emit('close')
				})
		},
		validateQuickfolder: function (event) {
			if (!this.quickfolderName) {
				event.stopPropagation()
			}
		}
	}
}
</script>
